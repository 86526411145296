var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        { attrs: { "label-width": "80px", model: _vm.formData } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "名称" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.formData.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动区域" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.formData.region,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "region", $$v)
                  },
                  expression: "formData.region",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动形式" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.formData.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "type", $$v)
                  },
                  expression: "formData.type",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }