var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "delete-column-container" },
    [_c("el-alert", { attrs: { closable: _vm.closable, title: _vm.title } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }