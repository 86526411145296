var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "social-container" },
    [
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.handleSocialLogin } },
        [_vm._v(" 点击进行Github登录 ")]
      ),
      _vm.data ? _c("p", [_vm._v(_vm._s(_vm.data))]) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }