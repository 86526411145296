var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-col",
    { attrs: { lg: 8, md: 12, sm: 24, xl: 6, xs: 24 } },
    [
      _c(
        "el-card",
        {
          attrs: { shadow: "hover" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [_c("span", [_vm._v(_vm._s(_vm.title))])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("vab-chart", {
            attrs: {
              "init-options": _vm.initOptions,
              option: _vm.option,
              theme: "vab-echarts-theme",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }