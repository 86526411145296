var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "property-dialog" },
    [
      _vm.nodeData.type === "user"
        ? _c("User", {
            attrs: { lf: _vm.lf, "node-data": _vm.nodeData },
            on: { onClose: _vm.handleClose },
          })
        : _c("CommonProperty", {
            attrs: { lf: _vm.lf, "node-data": _vm.nodeData },
            on: { onClose: _vm.handleClose },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }