var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "notice-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { lg: 8, md: 12, sm: 24, xl: 8, xs: 24 } },
            [
              _c(
                "el-card",
                {
                  attrs: { shadow: "hover" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function () {
                        return [_c("span", [_vm._v("Alert 警告")])]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("el-alert", {
                    attrs: {
                      "show-icon": "",
                      title: "成功提示的文案",
                      type: "success",
                    },
                  }),
                  _c("el-alert", {
                    attrs: {
                      "show-icon": "",
                      title: "消息提示的文案",
                      type: "info",
                    },
                  }),
                  _c("el-alert", {
                    attrs: {
                      "show-icon": "",
                      title: "警告提示的文案",
                      type: "warning",
                    },
                  }),
                  _c("el-alert", {
                    attrs: {
                      "show-icon": "",
                      title: "错误提示的文案",
                      type: "error",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 8, md: 12, sm: 24, xl: 8, xs: 24 } },
            [
              _c(
                "el-card",
                {
                  attrs: { shadow: "hover" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function () {
                        return [
                          _c("span", [
                            _vm._v("element-plus风格 Message 消息提示"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "", type: "primary" },
                      on: { click: _vm.open1 },
                    },
                    [_vm._v("消息")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "", type: "success" },
                      on: { click: _vm.open2 },
                    },
                    [_vm._v("成功")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "", type: "warning" },
                      on: { click: _vm.open3 },
                    },
                    [_vm._v("警告")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "", type: "danger" },
                      on: { click: _vm.open4 },
                    },
                    [_vm._v("错误")]
                  ),
                ],
                1
              ),
              _c(
                "el-card",
                {
                  attrs: { shadow: "hover" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function () {
                        return [_c("span", [_vm._v("hey-ui风格 消息提示")])]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "", type: "primary" },
                      on: { click: _vm.open5 },
                    },
                    [_vm._v("消息")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "", type: "success" },
                      on: { click: _vm.open6 },
                    },
                    [_vm._v("成功")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "", type: "warning" },
                      on: { click: _vm.open7 },
                    },
                    [_vm._v("警告")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "", type: "danger" },
                      on: { click: _vm.open8 },
                    },
                    [_vm._v("错误")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 8, md: 12, sm: 24, xl: 8, xs: 24 } },
            [
              _c(
                "el-card",
                {
                  attrs: { shadow: "hover" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function () {
                        return [
                          _c("span", [_vm._v("Notification Message 消息提示")]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "", type: "info" },
                      on: { click: _vm.open9 },
                    },
                    [_vm._v("消息")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "", type: "primary" },
                      on: { click: _vm.open10 },
                    },
                    [_vm._v("成功")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "", type: "warning" },
                      on: { click: _vm.open11 },
                    },
                    [_vm._v("警告")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "", type: "danger" },
                      on: { click: _vm.open12 },
                    },
                    [_vm._v("错误")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }