var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "goods-container" },
    [
      _c("el-alert", {
        attrs: {
          closable: false,
          "show-icon": "",
          title: _vm.title,
          type: "success",
        },
      }),
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-right-panel",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { inline: true, model: _vm.queryForm },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入商品名称" },
                        model: {
                          value: _vm.queryForm.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "title", $$v)
                          },
                          expression: "queryForm.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-search",
                            "native-type": "submit",
                            type: "primary",
                          },
                          on: { click: _vm.handleQuery },
                        },
                        [_vm._v(" 查询 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        _vm._l(_vm.list, function (item, index) {
          return _c(
            "el-col",
            { key: index, attrs: { lg: 8, md: 8, sm: 8, xl: 6, xs: 24 } },
            [
              _c(
                "el-card",
                {
                  attrs: { "body-style": { padding: "0px" }, shadow: "hover" },
                },
                [
                  _c("div", { staticClass: "goods-card-body" }, [
                    _c(
                      "div",
                      { staticClass: "goods-tag-group" },
                      [
                        _c("el-tag", { attrs: { hit: "" } }, [
                          _vm._v(_vm._s(item.company)),
                        ]),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "goods-image-group" }, [
                      _c(
                        "div",
                        { staticClass: "goods-image" },
                        [
                          item.icon
                            ? _c("vab-icon", { attrs: { icon: item.icon } })
                            : _vm._e(),
                          item.image || item.svg
                            ? _c("el-image", {
                                class: { isSvg: item.svg },
                                attrs: {
                                  fit: "cover",
                                  src: item.image || item.svg,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "goods-title" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "goods-price" },
                      [
                        _c("el-tag", { attrs: { hit: "", type: "warning" } }, [
                          _vm._v(_vm._s(item.price)),
                        ]),
                        _vm._l(item.label, function (label) {
                          return _c(
                            "el-tag",
                            { key: label, attrs: { hit: "" } },
                            [_vm._v(" " + _vm._s(label) + " ")]
                          )
                        }),
                      ],
                      2
                    ),
                    _c("div", { staticClass: "goods-description" }, [
                      _vm._v(" " + _vm._s(item.description) + " "),
                    ]),
                    _c("div", { staticClass: "goods-detail" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              item.url +
                              "?hmsr=admin-pro&hmpl=&hmcu=&hmkw=&hmci=",
                            target: "_blank",
                          },
                        },
                        [
                          _c("el-button", { attrs: { type: "text" } }, [
                            _vm._v("查看详情"),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          )
        }),
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.queryForm.pageNo,
          layout: _vm.layout,
          "page-size": _vm.queryForm.pageSize,
          total: _vm.total,
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }