<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="80px"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="商户名称">
            <el-input
              v-model="listQuery.shopName"
              clearable
              placeholder="商户名称"
            />
          </el-form-item>
          <el-form-item label="发票类型">
            <el-select
              v-model="listQuery.titleType"
              clearable
              placeholder="发票类型"
            >
              <el-option
                v-for="item in titleTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="发票状态">
            <el-select
              v-model="listQuery.status"
              clearable
              placeholder="发票状态"
            >
              <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="getList">
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="resetQuery">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-card class="box-card">
      <el-row style="margin-bottom: 10px">
        <!-- <el-button icon="el-icon-refresh" type="primary" @click="getList">
          刷新
        </el-button> -->
      </el-row>
      <el-table v-loading="loading" border :data="tableData" stripe>
        <el-table-column align="center" label="序号" width="50">
          <template #default="{ $index }">
            {{ $index + 1 }}
          </template>
        </el-table-column>
        <el-table-column align="left" label="商户名称" prop="shopName" />
        <el-table-column align="center" label="发票类型">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.titleType">
              {{ fotmat(scope.row.titleType, titleTypeList) }}
            </el-tag>
            <el-tag v-else type="warning">
              {{ fotmat(scope.row.titleType, titleTypeList) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="发票状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status">
              {{ fotmat(scope.row.status, statusList) }}
            </el-tag>
            <el-tag v-else type="success">
              {{ fotmat(scope.row.status, statusList) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="left" label="收票人姓名" prop="consigneeName" />
        <el-table-column align="left" label="银行账号" prop="bankCardNo" />
        <!-- <el-table-column align="left" label="省" prop="provinceCode" />
        <el-table-column align="left" label="市" prop="cityCode" />
        <el-table-column align="left" label="区县" prop="districtCode" /> -->
        <el-table-column align="center" label="创建时间" prop="createTime" />
        <el-table-column align="center" fixed="right" label="操作" width="80">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="handleDetail(scope.row, 0)"
            >
              详情
            </el-button>
          </template>
        </el-table-column>
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </el-table>

      <Pagination
        :limit.sync="listQuery.pageSize"
        :page.sync="listQuery.pageNumber"
        :total-count="totalCount"
        @pagination="getList"
      />

      <Dialog
        :height="dailog.height"
        :model-value="dailog.visible"
        :title="dailog.title"
        :visible="dailog.visible"
        :width="dailog.width"
        @handleCloseDialog="handleCloseDialog"
        @handleConfirm="handleConfirm"
      >
        <template #content>
          <el-descriptions border class="margin-top" :column="2" size="medium">
            <el-descriptions-item>
              <template slot="label">商户名称</template>
              {{ formData.shopName }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">银行账号</template>
              {{ formData.bankCardNo }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">收票人姓名</template>
              {{ formData.consigneeName }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">发票类型</template>
              {{ $arrFindItem(formData.titleType, titleTypeList) }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">发票状态</template>
              {{ $arrFindItem(formData.status, statusList) }}
            </el-descriptions-item>
          </el-descriptions>
        </template>
      </Dialog>
    </el-card>
  </div>
</template>

<script>
  import Pagination from '@/components/pagination.vue'
  import Dialog from '@/components/dialog.vue'
  import { billList } from '@/api/merchant'
  export default {
    name: 'Billing',
    components: {
      Pagination,
      Dialog,
    },
    data() {
      return {
        disabled: false,
        dailog: {
          height: 140,
          title: '商户开票记录',
          visible: false,
          width: '700px',
        },
        formData: {},
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          titleType: '',
          status: '',
          shopName: '',
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        rules: {},
        titleTypeList: [
          {
            label: '个人',
            value: 1,
          },
          {
            label: '公司',
            value: 2,
          },
        ],
        statusList: [
          {
            label: '开票中 ',
            value: 1,
          },
          {
            label: '已开票 ',
            value: 2,
          },
        ],
      }
    },
    created() {
      this.getList()
    },
    methods: {
      //查询
      async getList() {
        this.loading = true
        const {
          data: { list, totalRow },
        } = await billList(this.listQuery)
        this.loading = false
        this.tableData = list
        this.totalCount = totalRow
      },
      //编辑,查看
      handleDetail(row, val) {
        this.dailog.visible = true
        if (val === 0) {
          this.disabled = true
        } else {
          this.disabled = false
        }
        this.formData = row
      },
      // dialog确认
      handleConfirm() {
        if (this.disabled) {
          // 查看
          this.handleCloseDialog()
          return
        }
      },
      //打开添加dialog
      showDialog() {
        this.dailog.visible = true
        this.disabled = false
      },
      handleCloseDialog() {
        this.dailog.visible = false
        this.formData = {}
      },
      //重置
      resetQuery() {
        this.listQuery = {
          pageNumber: 1,
          pageSize: 20,
          titleType: '',
          status: '',
          shopName: '',
        }
        this.getList()
      },
      // 格式化
      fotmat(val, list) {
        const res = list.filter((item) => item.value === val)
        return res[0].label
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-range-editor.el-input__inner {
    width: 105%;
  }
  .el-card.is-always-shadow {
    box-shadow: none !important;
  }
  .box-card.el-card {
    border: none !important;
  }
  .el-dialog__body .el-form .el-col-12 {
    .el-input,
    .el-select {
      width: 300px;
    }
  }
</style>
