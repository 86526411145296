<template>
  <div class="index-container">部门管理模块内容区</div>
</template>

<script>
  export default {
    name: 'Index',
    data() {
      return {}
    },
  }
</script>

<style lang="scss" scoped>
  .index-container {
    padding: 0 !important;
    background: $base-color-background !important;
  }
</style>
