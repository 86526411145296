var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "80px", model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "角色码", prop: "role" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.role,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "role", $$v)
                  },
                  expression: "form.role",
                },
              }),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "菜单" } }, [
            _c(
              "div",
              { staticClass: "vab-tree-border" },
              [
                _c("el-tree", {
                  ref: "tree",
                  attrs: {
                    data: _vm.list,
                    "default-checked-keys": [
                      "/",
                      "/vab",
                      "/other",
                      "/mall",
                      "/setting",
                      "/error",
                    ],
                    "default-expanded-keys": [],
                    "node-key": "path",
                    "show-checkbox": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ data }) {
                        return [_c("span", [_vm._v(_vm._s(data.meta.title))])]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "按钮权限" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.form.btnRolesCheckedList,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "btnRolesCheckedList", $$v)
                    },
                    expression: "form.btnRolesCheckedList",
                  },
                },
                _vm._l(_vm.btnRoles, function (item) {
                  return _c(
                    "el-checkbox",
                    { key: item.value, attrs: { label: item.value } },
                    [_vm._v(" " + _vm._s(item.lable) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }