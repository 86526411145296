var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "tool", staticClass: "flow-menu" },
    _vm._l(_vm.menuList, function (menu) {
      return _c("div", { key: menu.id }, [
        _c(
          "span",
          {
            staticClass: "vab-node-pmenu",
            on: {
              click: function ($event) {
                menu.open = !menu.open
              },
            },
          },
          [
            _c("i", {
              class: {
                "el-icon-caret-bottom": menu.open,
                "el-icon-caret-right": !menu.open,
              },
            }),
            _vm._v("  " + _vm._s(menu.name) + " "),
          ]
        ),
        _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: menu.open,
                expression: "menu.open",
              },
            ],
            staticClass: "vab-node-menu-ul",
          },
          [
            _c(
              "draggable",
              _vm._b(
                {
                  on: { end: _vm.end, start: _vm.move },
                  model: {
                    value: menu.children,
                    callback: function ($$v) {
                      _vm.$set(menu, "children", $$v)
                    },
                    expression: "menu.children",
                  },
                },
                "draggable",
                _vm.draggableOptions,
                false
              ),
              _vm._l(menu.children, function (subMenu) {
                return _c(
                  "li",
                  {
                    key: subMenu.id,
                    staticClass: "vab-node-menu-li",
                    attrs: { type: subMenu.type },
                  },
                  [
                    _c("i", { class: subMenu.ico }),
                    _vm._v(" " + _vm._s(subMenu.name) + " "),
                  ]
                )
              }),
              0
            ),
          ],
          1
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }