var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "node-panel" },
    _vm._l(_vm.nodeList, function (item) {
      return _c(
        "div",
        {
          key: item.text,
          staticClass: "node-item",
          on: {
            mousedown: function ($event) {
              return _vm.$_dragNode(item)
            },
          },
        },
        [
          _c("div", { staticClass: "node-item-icon", class: item.class }, [
            item.type === "user" || item.type === "time"
              ? _c("div", { staticClass: "shape" })
              : _vm._e(),
          ]),
          _c("span", { staticClass: "node-label" }, [
            _vm._v(_vm._s(item.text)),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }