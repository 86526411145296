var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cropper-container" },
    [
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.openDialog } },
        [_vm._v("点击打开头像裁剪")]
      ),
      _c("vab-cropper", { ref: "vabCropper" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }