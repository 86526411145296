var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "upload-container" },
    [
      _c("vab-upload", {
        ref: "vabUpload",
        attrs: { limit: 50, name: "file", size: 2, url: "/upload" },
      }),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.handleShow()
            },
          },
        },
        [_vm._v("模拟上传")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }