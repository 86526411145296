var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("vab-json-viewer", {
        attrs: { copyable: "", "expand-depth": 5, sort: "", value: _vm.data },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }