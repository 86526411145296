<template>
  <div class="comprehensive-table-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="80px"
          :model="queryForm"
          @submit.native.prevent
        >
          <el-form-item label="商户名称">
            <el-input
              v-model="queryForm.shopName"
              clearable
              placeholder="商户名称"
            />
          </el-form-item>
          <el-form-item label="设备编号">
            <el-input
              v-model="queryForm.deviceNo"
              clearable
              placeholder="设备编号"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="handleQuery"
            >
              查询
            </el-button>
            <!-- <el-button type="text" @click="handleFold">
              <span v-if="fold">展开</span>
              <span v-else>合并</span>
              <vab-icon
                class="vab-dropdown"
                :class="{ 'vab-dropdown-active': fold }"
                icon="arrow-up-s-line"
              />
            </el-button> -->
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-table
      v-loading="loading"
      border
      :data="tableData"
      stripe
      @selection-change="setSelectRows"
    >
      <el-table-column align="center" type="selection" width="50" />
      <el-table-column
        align="center"
        label="序号"
        prop="index"
        sortable
        width="80"
      />
      <el-table-column align="center" label="商户名称" prop="shopName" />
      <el-table-column align="center" label="房源总数" prop="houseCount" />
      <el-table-column align="center" label="绑定水表" prop="waterBindCount" />
      <el-table-column align="center" label="绑定电表" prop="eleBindCount" />
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="handleview(scope.row)">
            查看商户绑定的设备
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <Pagination
      :limit.sync="listQuery.pageSize"
      :page.sync="listQuery.pageNumber"
      :total-count="totalCount"
      @pagination="fetchData"
    />
  </div>
</template>

<script>
  import { PageShopBindDevice } from '@/api/device/merchant'
  import Pagination from '@/components/pagination.vue'
  export default {
    shopName: 'DeviceType',
    components: {
      Pagination,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        queryForm: {
          shopName: '',
          deviceNo: '',
        },
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        fold: false,
      }
    },
    beforeMount() {
      window.addEventListener('resize', this.handleHeight)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleHeight)
    },
    created() {
      this.fetchData()
    },
    methods: {
      handleFold() {
        this.fold = !this.fold
        this.handleHeight()
      },
      handleHeight() {
        if (this.fold) this.height = this.$baseTableHeight(2) - 47
        else this.height = this.$baseTableHeight(3) - 30
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleview(row) {
        // this.$refs['edit'].showEdit(row)
        console.log(row)
        this.$router.push('/admin/device/merchant/manage')
      },
      handleQuery() {
        this.listQuery.pageNumber = 1
        this.fetchData()
      },
      async fetchData() {
        this.loading = true
        const {
          data: { list, totalRow },
        } = await PageShopBindDevice({ ...this.queryForm, ...this.listQuery })
        this.tableData = list.map((item, index) => {
          return {
            ...item,
            index: index + 1,
          }
        })
        this.totalCount = totalRow
        this.loading = false
      },
      toggleSelection(rows) {
        if (rows) {
          rows.forEach((row) => {
            this.$refs.tableSort.toggleRowSelection(row)
          })
        } else {
          this.$refs.tableSort.clearSelection()
        }
      },
    },
  }
</script>
