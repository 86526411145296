<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="80px"
          :model="listQuery"
          @submit.native.prevent
        >
          <el-form-item label="商户名称">
            <el-input
              v-model="listQuery.keywords"
              clearable
              placeholder="请输入商户名称"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="getList">
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="resetQuery">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-card class="box-card">
      <el-row style="margin-bottom: 10px">
        <el-button icon="el-icon-plus" type="primary" @click="showDialog">
          新增
        </el-button>
        <el-button icon="el-icon-delete" type="danger" @click="del">
          删除
        </el-button>
      </el-row>
      <el-table
        v-loading="loading"
        border
        :data="tableData"
        stripe
        @select="selectRow"
        @select-all="selectRow"
      >
        <el-table-column align="center" type="selection" width="50" />
        <el-table-column align="center" label="序号" width="50">
          <template #default="{ $index }">
            {{ $index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          align="left"
          label="商户名称"
          prop="name"
          width="200"
        />
        <!--<el-table-column
          align="left"
          label="简称"
          prop="shortName"
          width="200"
        />-->
        <el-table-column
          align="left"
          label="电子合同费用"
          prop="noPaperContractFee"
          width="180"
        />
        <el-table-column
          align="left"
          label="平台服务费"
          prop="platformServiceFee"
          width="180"
        />
        <el-table-column align="left" label="短信发送费用" prop="sendMsgFee" />
        <el-table-column
          align="left"
          label="支付分账服务费"
          prop="splitServiceFee"
          width="180"
        />
        <el-table-column
          align="left"
          label="支付手续费"
          prop="payServiceFee"
          width="180"
        />
        <el-table-column
          align="left"
          label="自动提现服务费"
          prop="withdrawServiceFee"
          width="180"
        />

        <el-table-column
          align="center"
          label="创建时间"
          prop="createTime"
          width="180"
        />

        <el-table-column align="center" fixed="right" label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              plain
              size="mini"
              type="primary"
              @click="handleDetail(scope.row, 0)"
            >
              查看
            </el-button>
            <el-button
              plain
              size="mini"
              type="success"
              @click="handleDetail(scope.row, 1)"
            >
              编辑
            </el-button>
          </template>
        </el-table-column>
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </el-table>

      <Pagination
        :limit.sync="listQuery.pageSize"
        :page.sync="listQuery.pageNumber"
        :total-count="totalCount"
        @pagination="getList"
      />

      <!-- 新增/编辑 -->
      <Dialog
        :height="dailog.height"
        :model-value="dailog.visible"
        :title="dailog.title"
        :visible="dailog.visible"
        :width="dailog.width"
        @handleCloseDialog="handleCloseDialog"
        @handleConfirm="handleConfirm"
      >
        <template #content>
          <el-form
            ref="formData"
            label-width="150px"
            :model="formData"
            :rules="rules"
            size="small"
          >
            <el-col :span="24" style="height: 50px">
              <el-form-item
                v-if="!disabled_shopId"
                label="商户名称"
                prop="shopId"
              >
                <el-select
                  v-model="formData.shopId"
                  clearable
                  :disabled="disabled_shopId"
                  filterable
                  style="width: 300px; max-width: 300px"
                >
                  <el-option
                    v-for="item in shopListData"
                    :key="item.id"
                    :label="item.shortName"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
              <el-form-item v-else label="商户名称">
                {{ formData.name }}
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="电子合同费用" prop="noPaperContractFee">
                <el-input
                  v-model="formData.noPaperContractFee"
                  clearable
                  :disabled="disabled"
                  :min="0"
                  style="max-width: 300px"
                  type="number"
                  @keyup.native="preventNeg($event)"
                  @mousewheel.native.prevent
                >
                  <template slot="append">元/笔</template>
                </el-input>
                <span class="tip-info_css">
                  参考值： 0.00 || 取值区间: 0.00 - 0.00 || 单位: 0 || 备注: 0
                </span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="平台服务费" prop="platformServiceFee">
                <el-input
                  v-model="formData.platformServiceFee"
                  clearable
                  :disabled="disabled"
                  :min="0"
                  style="max-width: 300px"
                  type="number"
                  @keyup.native="preventNeg($event)"
                  @mousewheel.native.prevent
                >
                  <template slot="append">元/笔</template>
                </el-input>
                <span class="tip-info_css">
                  参考值: 3.00 || 取值区间: 0.10 - 2.00 || 单位: 元/笔 || 备注:
                  xx
                </span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="短信发送费用" prop="sendMsgFee">
                <el-input
                  v-model="formData.sendMsgFee"
                  :disabled="disabled"
                  :min="0"
                  style="max-width: 300px"
                  type="number"
                  @keyup.native="preventNeg($event)"
                  @mousewheel.native.prevent
                >
                  <template slot="append">元/笔</template>
                </el-input>
                <span class="tip-info_css">
                  参考值: 0.10 || 取值区间: 0.10 - 0.20 || 单位: 元/条 || 备注:
                  元/条
                </span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="支付分账服务费" prop="splitServiceFee">
                <el-input
                  v-model="formData.splitServiceFee"
                  :disabled="disabled"
                  :min="0"
                  style="max-width: 300px"
                  type="number"
                  @keyup.native="preventNeg($event)"
                  @mousewheel.native.prevent
                >
                  <template slot="append">元/笔</template>
                </el-input>
                <span class="tip-info_css">
                  参考值:0.10 || 取值区间: 0.01 - 1.00 || 单位: 元/笔 ||
                  备注：百分比
                </span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="支付手续费" prop="payServiceFee">
                <el-input
                  v-model="formData.payServiceFee"
                  :disabled="disabled"
                  :min="0"
                  style="max-width: 300px"
                  type="number"
                  @keyup.native="preventNeg($event)"
                  @mousewheel.native.prevent
                >
                  <template slot="append">%/笔</template>
                </el-input>
                <span class="tip-info_css">
                  参考值: 0.38 || 取值区间: 0.01 - 1.00 || 单位: % || 备注:
                  百分比
                </span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="自动提现服务费" prop="withdrawServiceFee">
                <el-input
                  v-model="formData.withdrawServiceFee"
                  :disabled="disabled"
                  :min="0"
                  style="max-width: 300px"
                  type="number"
                  @keyup.native="preventNeg($event)"
                  @mousewheel.native.prevent
                >
                  <template slot="append">元/笔</template>
                </el-input>
                <span class="tip-info_css">
                  参考值: 1.00 || 取值区间: 0.10 - 1.00 || 单位: 元/笔 || 备注:
                  xx
                </span>
              </el-form-item>
            </el-col>
          </el-form>
        </template>
      </Dialog>

      <!-- 详情 -->
      <Dialog
        :height="dailog1.height"
        :model-value="dailog1.visible"
        :showfoot="dailog1.showfoot"
        :title="dailog1.title"
        :visible="dailog1.visible"
        :width="dailog1.width"
        @handleCloseDialog="handleCloseDialog1"
      >
        <template #content>
          <el-descriptions border class="margin-top" :column="2" size="medium">
            <el-descriptions-item>
              <template slot="label">商户名称</template>
              {{ formData.name }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">电子合同费</template>
              {{ formData.noPaperContractFee }}元/笔
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">平台服务费</template>
              {{ formData.platformServiceFee }}元/笔
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">短信费</template>
              {{ formData.sendMsgFee }}元/笔
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">分账服务费</template>
              {{ formData.splitServiceFee }}元/笔
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">支付手续费</template>
              {{ formData.payServiceFee }}%/笔
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">自动提现手续费</template>
              {{ formData.withdrawServiceFee }}元/笔
            </el-descriptions-item>
          </el-descriptions>
        </template>
      </Dialog>
    </el-card>
  </div>
</template>

<script>
  import Pagination from '@/components/pagination.vue'
  import { CommonMixin } from '@/mixins/CommonMixin'
  import Dialog from '@/components/dialog.vue'
  import { costList, costDel, costAdd, costUpdate } from '@/api/merchant/index'
  import { shopListFilter } from '@/api/basicssetting'
  export default {
    name: 'Index',
    components: {
      Pagination,
      Dialog,
    },
    mixins: [CommonMixin],
    data() {
      var checkNumber = (rule, value, callback) => {
        if (value < 0) {
          callback(new Error('请输入大于0的数字!'))
        } else {
          callback()
        }
      }
      return {
        disabled: false,
        disabled_shopId: false,
        size: '',
        dailog: {
          height: 400,
          title: '商户成本费用配置',
          visible: false,
          width: '1100px',
        },
        dailog1: {
          height: null,
          title: '商户成本费用配置详情',
          visible: false,
          width: '',
          showfoot: false,
        },
        formData: {},
        listQuery: {
          pageNumber: 1,
          pageSize: 20,
          keywords: '',
        },
        totalCount: 0,
        tableData: [],
        loading: false,
        selection: [],
        rules: {
          shopId: { required: true, message: '请选择', trigger: 'blur' },
          noPaperContractFee: [
            {
              required: true,
              message: '请输入',
              trigger: 'blur',
            },
            { validator: checkNumber, trigger: 'blur' },
          ],
          platformServiceFee: [
            {
              required: true,
              message: '请输入',
              trigger: 'blur',
            },
            { validator: checkNumber, trigger: 'blur' },
          ],
          sendMsgFee: [
            { required: true, message: '请输入', trigger: 'blur' },
            { validator: checkNumber, trigger: 'blur' },
          ],
          splitServiceFee: [
            {
              required: true,
              message: '请输入',
              trigger: 'blur',
            },
            { validator: checkNumber, trigger: 'blur' },
          ],
          payServiceFee: [
            { required: true, message: '请输入', trigger: 'blur' },
            { validator: checkNumber, trigger: 'blur' },
          ],
          withdrawServiceFee: [
            {
              required: true,
              message: '请输入',
              trigger: 'blur',
            },
            { validator: checkNumber, trigger: 'blur' },
          ],
        },
        shopListData: [],
      }
    },
    created() {
      this.getList()
      this.getShopList()
    },
    methods: {
      async getShopList() {
        const {
          data: { list },
        } = await shopListFilter()
        this.shopListData = list
        console.log(this.shopListData)
      },
      //查询
      async getList() {
        this.loading = true
        const {
          data: { list, totalRow },
        } = await costList(this.listQuery)
        this.loading = false
        this.tableData = list
        this.totalCount = totalRow
      },
      //编辑,查看 0查看  1编辑
      handleDetail(row, val) {
        if (val === 0) {
          this.dailog1.visible = true
          this.disabled = true
        } else {
          this.dailog.visible = true
          this.dailog.title = '编辑-商户成本费用配置'
          this.disabled = false
          this.disabled_shopId = true
        }
        this.formData = row
        console.log(this.formData.shopId)
      },
      // dialog确认
      async handleConfirm() {
        if (this.disabled) {
          // 查看
          this.handleCloseDialog()
          return
        }

        this.$refs['formData'].validate(async (valid) => {
          if (valid) {
            if (this.formData.id) {
              // 编辑
              await costUpdate(this.formData)
            } else {
              // 新增
              await costAdd(this.formData)
            }
            this.handleCloseDialog()
            await this.getList()
          }
        })
      },
      //打开添加dialog
      showDialog() {
        this.dailog.visible = true
        this.disabled = false
      },
      //表格选择
      selectRow(selection) {
        this.selection = selection
      },
      async del() {
        if (this.selection.length === 0) {
          this.$message.error('请先选择')
          return
        }
        this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          const list = []
          this.selection.forEach((item) => list.push(item.id))
          const params = {
            ids: list.join(','),
          }
          await costDel(params)
          await this.getList()
        })
      },
      handleCloseDialog() {
        this.dailog.visible = false
        this.disabled_shopId = false
        this.disabled = false
        this.formData = {}
      },
      handleCloseDialog1() {
        this.dailog1.visible = false
      },
      //重置
      resetQuery() {
        this.listQuery = {
          pageNumber: 1,
          pageSize: 20,
          keywords: '',
        }
        this.getList()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-range-editor.el-input__inner {
    width: 105%;
  }
  .el-card.is-always-shadow {
    box-shadow: none !important;
  }
  .box-card.el-card {
    border: none !important;
  }
  .el-dialog__body .el-form .el-col-12 {
    .el-input,
    .el-select {
      width: 300px;
    }
  }
  .tip-info_css {
    background-color: #e9ecef;
    height: calc(1.5em + 0.75rem + 2px);
    margin-left: 0.375rem;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    color: gray;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  ::v-deep() .el-descriptions--small.is-bordered .el-descriptions-item__cell {
    font-size: 15px !important;
  }
</style>
