var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "node",
      class: _vm.nodeContentClass,
      style: _vm.nodeContentStyle,
      on: { click: _vm.clickNode, mouseup: _vm.changeNodeSite },
    },
    [
      _c("div", { staticClass: "vab-node-left" }),
      _c("div", { staticClass: "vab-node-left-ico flow-node-drag" }, [
        _c("i", { class: _vm.nodeIcoClass }),
      ]),
      _c(
        "div",
        {
          staticClass: "vab-node-text",
          attrs: { "show-overflow-tooltip": true },
        },
        [_vm._v(" " + _vm._s(_vm.node.name) + " ")]
      ),
      _c("div", { staticClass: "vab-node-right-ico" }, [
        _c("i", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.node.state === "success",
              expression: "node.state === 'success'",
            },
          ],
          staticClass: "el-icon-circle-check el-node-state-success",
        }),
        _c("i", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.node.state === "error",
              expression: "node.state === 'error'",
            },
          ],
          staticClass: "el-icon-circle-close el-node-state-error",
        }),
        _c("i", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.node.state === "warning",
              expression: "node.state === 'warning'",
            },
          ],
          staticClass: "el-icon-warning-outline el-node-state-warning",
        }),
        _c("i", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.node.state === "running",
              expression: "node.state === 'running'",
            },
          ],
          staticClass: "el-icon-loading el-node-state-running",
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }