<template>
  <div class="index-container">ceshi</div>
</template>

<script>
  export default {
    name: 'Index',
    data() {
      return {}
    },
  }
</script>

<style lang="scss" scoped></style>
