var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "80px", model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "父节点", prop: "parentName" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    disabled: !_vm.form.parentId,
                    placeholder: "请选择父节点",
                  },
                  model: {
                    value: _vm.form.parentId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "parentId", $$v)
                    },
                    expression: "form.parentId",
                  },
                },
                [
                  _c(
                    "el-option",
                    {
                      staticStyle: { height: "auto", padding: "0" },
                      attrs: {
                        label: _vm.form.parentName,
                        value: _vm.form.parentId,
                      },
                    },
                    [
                      _c("el-tree", {
                        ref: "tree",
                        attrs: {
                          data: _vm.treeData,
                          "default-expand-all": "",
                          props: _vm.defaultProps,
                        },
                        on: { "node-click": _vm.handleNodeClick },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "名称", prop: "name" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "排序", prop: "order" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.order,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "order", $$v)
                  },
                  expression: "form.order",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }