var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "echarts-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("vab-chart-line", { attrs: { title: "折线图" } }),
          _c("vab-chart-bar", { attrs: { title: "柱状图" } }),
          _c("vab-chart-pie", { attrs: { title: "饼状图" } }),
          _c("vab-chart-scatter", { attrs: { title: "散点图" } }),
          _c("vab-chart-candlestick", { attrs: { title: "K线图" } }),
          _c("vab-chart-radar", { attrs: { title: "雷达图" } }),
          _c("vab-chart-treemap", { attrs: { title: "矩形树图" } }),
          _c("vab-chart-sunburst", { attrs: { title: "旭日图" } }),
          _c("vab-chart-funnel", { attrs: { title: "漏斗图" } }),
          _c("vab-chart-gauge", { attrs: { title: "仪表图" } }),
          _c("vab-chart-theme-river", { attrs: { title: "河流流向图" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }