var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "iframe-container" }, [
    _c("iframe", { attrs: { frameborder: "0", src: _vm.url } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }