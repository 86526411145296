var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "form", attrs: { "label-width": "80px", model: _vm.form } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "活动名称" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动区域" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择活动区域" },
                  model: {
                    value: _vm.form.region,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "region", $$v)
                    },
                    expression: "form.region",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: "区域一", value: "shanghai" },
                  }),
                  _c("el-option", {
                    attrs: { label: "区域二", value: "beijing" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动时间" } },
            [
              _c(
                "el-col",
                { attrs: { span: 11 } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: { placeholder: "选择日期", type: "date" },
                    model: {
                      value: _vm.form.date1,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "date1", $$v)
                      },
                      expression: "form.date1",
                    },
                  }),
                ],
                1
              ),
              _c("el-col", { staticClass: "line", attrs: { span: 2 } }, [
                _vm._v("-"),
              ]),
              _c(
                "el-col",
                { attrs: { span: 11 } },
                [
                  _c("el-time-picker", {
                    staticStyle: { width: "100%" },
                    attrs: { placeholder: "选择时间" },
                    model: {
                      value: _vm.form.date2,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "date2", $$v)
                      },
                      expression: "form.date2",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "即时配送" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.form.delivery,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "delivery", $$v)
                  },
                  expression: "form.delivery",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动性质" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                [
                  _c("el-checkbox", {
                    attrs: { label: "美食/餐厅线上活动", name: "type" },
                  }),
                  _c("el-checkbox", {
                    attrs: { label: "地推活动", name: "type" },
                  }),
                  _c("el-checkbox", {
                    attrs: { label: "线下主题活动", name: "type" },
                  }),
                  _c("el-checkbox", {
                    attrs: { label: "单纯品牌曝光", name: "type" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "特殊资源" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.resource,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "resource", $$v)
                    },
                    expression: "form.resource",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "线上品牌商赞助" } }),
                  _c("el-radio", { attrs: { label: "线下场地免费" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动形式" } },
            [
              _c("el-input", {
                attrs: { type: "textarea" },
                model: {
                  value: _vm.form.desc,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "desc", $$v)
                  },
                  expression: "form.desc",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }