var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "index-container" }, [
    _vm._v("岗位管理内容区"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }