var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "test2-container" },
    [
      _c("el-alert", {
        attrs: {
          closable: false,
          "show-icon": "",
          title: "取值方式：this.query",
          type: "success",
        },
      }),
      _c("vab-json-viewer", {
        attrs: { copyable: "", "expand-depth": 5, sort: "", value: _vm.route },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }