var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("input", { attrs: { placeholder: "Input Underline", type: "text" } }),
      _c("span"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }