var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tabs-container" },
    [
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.closeOthersTabs } },
        [_vm._v("关闭其他")]
      ),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.closeLeftTabs } },
        [_vm._v("关闭左侧")]
      ),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.closeRightTabs } },
        [_vm._v("关闭右侧")]
      ),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.closeAllTabs } },
        [_vm._v("关闭全部")]
      ),
      _c(
        "el-button",
        {
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.handleTabRemove(_vm.$route.path)
            },
          },
        },
        [_vm._v(" 关闭当前 ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }